/**
 * SID 站点id
 * siteConfig 站点信息
 * LOGIN_URL 登录地址
 * SHOWED_EXAM_GRADE_TIP_MODAL 是否显示考试成绩提示modal
 */
enum KEYS {
  SID = 'sid',
  SITE_CONFIG = 'siteConfig',
  LOGIN_URL = 'loginUrl',
  SHOWED_EXAM_GRADE_TIP_MODAL = 'showed_exam_grade_tip_modal',
}

/**
 * 获取 localStorage
 * @param key
 * @returns
 */
export const getLocalStorage = (key: keyof typeof KEYS) => {
  if (typeof window === 'undefined') return;
  const res = localStorage.getItem(KEYS[key]) as any;

  try {
    return JSON.parse(res);
  } catch (e) {
    return res;
  }
};

/**
 * 通用写入 localStorage
 * @param key
 * @param val
 */
export const setLocalStorage = (key: keyof typeof KEYS, val: any) => {
  if (typeof window === 'undefined') return;
  localStorage.setItem(
    KEYS[key],
    typeof val === 'object' ? JSON.stringify(val) : val,
  );
};

/**
 * 删除
 * @param key
 */
export const delLocalStorage = (key: keyof typeof KEYS) => {
  if (!window) return;
  localStorage.removeItem(key);
};
