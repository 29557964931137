import { getSiteConfig, updateToken } from '@/servers/http/api';
import './app.less';
import Vconsole from 'vconsole';
import setLogo from './utils/setLogo';
// import './plugins/sentry';
import { getCookie, setLocalStorage } from '@/storage';

export async function render(oldRender: any) {
  console.log('render');

  if (['test', 'pre'].includes(BUILD_ENV)) {
    new Vconsole();
  }
  //判断是否登录 ，并设置站点信息
  let siteConfig: any = '';
  let token;

  const { data } = await getSiteConfig();
  setLocalStorage('SITE_CONFIG', data);
  siteConfig = data;
  const sid = siteConfig.platform_sid;
  const loginUrl = siteConfig?.config.h5_user_center_url;
  // 设置网页标题
  const wap_logo = siteConfig?.config?.wap_logo;
  setLogo(wap_logo);
  setLocalStorage('SID', sid);
  setLocalStorage('LOGIN_URL', loginUrl);
  if (sid) {
    token = getCookie('TOKEN');
  }
  if (token) {
    await updateToken();
    oldRender();
  } else {
    oldRender();
  }
}
