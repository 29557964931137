import Cookies from 'js-cookie';
import { getLocalStorage } from './localStorage';

/**
 * 默认过期时间
 */
const DEFAULT_EXPIRES = 1;

/**
 * TOKEN 用户token
 */
enum KEYS {
  TOKEN = 'h5Token',
}

/** 需要使用sid区分的键 */
const NEED_SID_KEYS = ['TOKEN'];

/**
 * 获取 cookie
 * @param key
 * @returns
 */
export const getCookie = (key: keyof typeof KEYS) => {
  let res = '';
  if (NEED_SID_KEYS.includes(key)) {
    const sid = getLocalStorage('SID') || '';
    res = Cookies.get(`${KEYS[key]}${sid}`) as any;
  } else {
    res = Cookies.get(KEYS[key]) as any;
  }
  try {
    return JSON.parse(res);
  } catch (e) {
    return res;
  }
};

/**
 * 写入 cookie
 * @param key
 * @param data
 * @param expires
 */
export const setCookie = (
  key: keyof typeof KEYS,
  data: any,
  expires: number = DEFAULT_EXPIRES,
) => {
  if (!window) return;
  const ipReg =
    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  let domain = '';
  if (!window.location.hostname.match(ipReg)) {
    const tempDomain = window.location.hostname.split('.') || [];
    if (tempDomain.length > 2) {
      const domainArr = tempDomain.slice(-2);
      domain = '.' + domainArr.join('.');
    }
  }
  if (NEED_SID_KEYS.includes(key)) {
    const sid = getLocalStorage('SID') || '';

    Cookies.set(
      `${KEYS[key]}${sid}`,
      typeof data === 'object' ? JSON.stringify(data) : data,
      {
        expires,
        domain,
      },
    );
  } else {
    Cookies.set(
      KEYS[key],
      typeof data === 'object' ? JSON.stringify(data) : data,
      {
        expires,
        domain,
      },
    );
  }
};

/**
 * 删除
 * @param key
 */
export const delCookie = (key: keyof typeof KEYS) => {
  if (!window) return;
  const ipReg =
    /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  let domain = '';
  if (!window.location.hostname.match(ipReg)) {
    const tempDomain = window.location.hostname.split('.') || [];
    if (tempDomain.length > 2) {
      const domainArr = tempDomain.slice(-2);
      domain = '.' + domainArr.join('.');
    }
  }

  if (NEED_SID_KEYS.includes(key)) {
    const sid = getLocalStorage('SID') || '';
    Cookies.remove(`${KEYS[key]}${sid}`, { domain });
  } else {
    Cookies.remove(KEYS[key], { domain });
  }
};
