import { Toast } from 'antd-mobile';
import axios from 'axios';
import { history } from 'umi';
import { getCookie, getLocalStorage } from '@/storage';
import { transformSiteData } from "@wotu/kp-components"
import { goToLogin } from '@/utils';

const getKingUrlByEnv = () => {
  let url = '';
  switch (BUILD_ENV) {
    case 'dev':
      url = 'https://eapif.wozp.cn'; // 开发
      break;
    case 'test':
      url = 'https://eapif.wozp.cn'; //测试
      break;
    case 'pre':
      url = 'https://reapif.wozp.cn'; // 灰度预发
      break;
    case 'pro':
      url = `https://eapif.wozhipei.com`; //正式
      break;
    default:
      // 开发
      url = 'https://eapif.wozp.cn';
      break;
  }
  return url;
};

export let kingUrl = getKingUrlByEnv();

const Http = axios.create({
  baseURL: getKingUrlByEnv(),
  timeout: 80000,
});

/******
 * request拦截器==>对请求参数做处理
 * ******/
Http.interceptors.request.use(
  (config) => {
    const { url, delayTime = 3000 } = config;
    if (url.includes('uploads')) {
      config.timeout = 600000;
    } else {
      config.timeout = 8000;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    // 配置err显示
    Promise.reject(error);
  },
);

/**
 * 拦截响应response，并做一些错误处理
 */
Http.interceptors.response.use(
  (response) => {
    // 成功请求到数据的处理
    // 这里根据后端提供的数据进行对应的处理
    const { config } = response;
    if (config.url === "/sites/config") {
      response.data = transformSiteData(response.data)
    }
    return Promise.resolve(response);
  },
  (error) => {
    let { response, code, config } = error;
    response = response || {};
    config = config || {};
    let { data, status } = response;
    console.log(
      '-------------------------------error----------------------------------',
    );
    console.log(response);
    console.log(code);
    console.log(config);
    console.log(error.message);

    console.log(
      '-------------------------------response----------------------------------',
    );
    console.log(data);
    console.log(status);

    if (code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      Toast.show({
        icon: 'fail',
        content: '请求超时,请稍后再试!',
      });
      return Promise.reject('请求超时,请稍后再试!');
    }
    if (axios.isCancel(error)) {
      return {};
    }
    if (status === undefined) {
      return Promise.reject({
        message: '数据有误，请联系管理员',
        data: { message: '数据有误，请联系管理员' },
      });
    }

    const { location } = history || {};
    const { pathname, search } = location || {};
    switch (Number(status || 0)) {
      case 400:
        break;
      case 401:
        goToLogin()
        return Promise.reject(error.response);
      case 403:
        break;
      case 404:
        break;
      default:
    }
    if (!config.noMsg) {
      if (Array.isArray(data)) {
        let message = data[0] ? data[0].message : data.message;
        Toast.show({
          icon: 'fail',
          content: message,
        });
      } else {
        if (typeof data === 'object') {
          Toast.show({
            icon: 'fail',
            content: data.message,
          });
        }
      }
    }
    return Promise.reject(error.response);
  },
);

export default Http;

const baseRequest = async (
  url,
  method = 'get',
  data = {},
  params = {},
  header = {},
  noMsg = history?.location?.pathname?.slice(0, 9) !== '/mockExam'
    ? false
    : true,
  delayTime = 3000,
  single,
) => {
  const token = getCookie('TOKEN');
  if (token) {
    header.Authorization = 'Bearer ' + token;
  }

  header['authorization-platform'] = token;

  let myHeaders = {
    'Content-Type': 'application/json',
    ...header,
  };

  if (ALIAS_ENV !== 'global' && ALIAS_ENV) {
    myHeaders['X-Site-Alias'] = ALIAS_ENV;
  }

  const request = Http({
    url,
    method,
    data,
    params,
    noMsg,
    delayTime,
    headers: myHeaders,
    single: single,
  });
  return request;
};

/**
 * get请求
 * @param urlLink 请求地址
 * @param param 请求地址
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const getData = (urlLink, param = {}, options = {}) => {
  const { noMsg = false, delayTime = 3000, headers = {} } = options || {};
  return baseRequest(
    urlLink,
    'get',
    {},
    param,
    { ...headers },
    noMsg,
    delayTime,
  );
};

/**
 * post请求
 * @param urlLink 请求地址
 * @param param 请求地址
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const postData = (urlLink, param = {}, options = {}) => {
  const { noMsg = false, delayTime = 3000, single = undefined } = options || {};
  return baseRequest(urlLink, 'post', param, {}, {}, noMsg, delayTime, single);
};

/**
 * 上传文件请求
 * @param urlLink 请求地址
 * @param param 表单数据{Object}内部必须file键值对
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const postFile = (urlLink, param = {}, options = {}) => {
  const { noMsg = false, delayTime = 3000 } = options || {};
  const fileData = new FormData();
  for (const key in param) {
    fileData.append(key, param[key]);
  }
  // 通过append向form对象添加数据
  return baseRequest(
    urlLink,
    'post',
    fileData,
    {},
    { 'Content-Type': 'multipart/form-data' },
    noMsg,
    delayTime,
  );
};

/**
 * delete请求
 * @param urlLink 请求地址
 * @param param 请求地址
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const deleteData = (urlLink, param = {}, options = {}) => {
  const { noMsg = false, delayTime = 3000 } = options || {};
  return baseRequest(urlLink, 'delete', param, {}, {}, noMsg, delayTime);
};

/**
 * delete请求
 * @param urlLink 请求地址
 * @param param 请求地址
 * @param options 请求处理设置
 * @returns {Promise<AxiosResponse>}
 */
export const putData = (urlLink, param = {}, options = {}) => {
  const { noMsg = false, delayTime = 3000, single = undefined } = options || {};
  return baseRequest(urlLink, 'put', param, {}, {}, noMsg, delayTime, single);
};
