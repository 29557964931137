import { getLocalStorage } from "@/storage"


export const goToLogin = () => {
  const { domain } = getLocalStorage("SITE_CONFIG") || {}
  const { wapDomain } = domain || {}
  if (!wapDomain) return
  const skipDomain = wapDomain.split(",")[0]
  window.location.href = `${skipDomain}/account/user/login?back=${encodeURIComponent(window.location.href)}`

}
