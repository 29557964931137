// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/workspace/exam-fe-score-h5_CnHg/node_modules/.pnpm/registry.npmmirror.com+@umijs+runtime@3.5.41_react@16.14.0/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/*",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
    "exact": true,
    "routes": [
      {
        "path": "/",
        "redirect": "/workbench",
        "exact": true
      },
      {
        "path": "/workbench",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__index' */'@/pages/workbench/index'), loading: LoadingComponent}),
        "title": "工作台-沃考评",
        "exact": true
      },
      {
        "path": "/workbench/historical-score",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__historicalScore__index' */'@/pages/workbench/historicalScore/index'), loading: LoadingComponent}),
        "title": "历史评分-沃考评",
        "exact": true
      },
      {
        "path": "/score-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__scoreDetail__index' */'@/pages/scoreDetail/index'), loading: LoadingComponent}),
        "title": "查看配分-沃考评",
        "exact": true
      },
      {
        "path": "/workbench/historical-score/check-score/:id",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__historicalScore__checkScore__index' */'@/pages/workbench/historicalScore/checkScore/index'), loading: LoadingComponent}),
        "title": "查看评分-沃考评",
        "exact": true
      },
      {
        "path": "/certificate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__certificate__index' */'@/pages/certificate/index'), loading: LoadingComponent}),
        "title": "证书查询结果",
        "exact": true
      },
      {
        "path": "/self-certificate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__self-certificate' */'@/pages/self-certificate'), loading: LoadingComponent}),
        "title": "我的证书",
        "exact": true
      },
      {
        "path": "/test-certificate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__test-certificate__index' */'@/pages/test-certificate/index'), loading: LoadingComponent}),
        "title": "证书查询测试页",
        "exact": true
      },
      {
        "path": "/workbench/check-in-record",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__workbench__checkInRecord__index' */'@/pages/workbench/checkInRecord/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/check-in",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__checkIn__index' */'@/pages/checkIn/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/exam",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exam__index' */'@/pages/exam/index'), loading: LoadingComponent}),
        "title": "考试评分-沃考评",
        "exact": true
      },
      {
        "path": "/exam/grade-detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exam__gradeDetail' */'@/pages/exam/gradeDetail'), loading: LoadingComponent}),
        "title": "考试评分-沃考评",
        "exact": true
      },
      {
        "path": "/exam/submit-grade",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exam__submitGrade__index' */'@/pages/exam/submitGrade/index'), loading: LoadingComponent}),
        "title": "提交成绩-沃考评",
        "exact": true
      },
      {
        "path": "/exam/submit-grade/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exam__submitGrade__detail__index' */'@/pages/exam/submitGrade/detail/index'), loading: LoadingComponent}),
        "title": "提交成绩-沃考评",
        "exact": true
      },
      {
        "path": "/u/:code",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__exam-apply__examine__detail' */'@/pages/exam-apply/examine/detail'), loading: LoadingComponent}),
        "title": "考试申请审核查看页",
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
